<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DE ACCESOS AL SISTEMA DURANTE EL DÍA DE HOY:
                    </span>
                    <hr>
                    <CChartLine v-if="showEstadistica" :options="optionsEstadisticas" :datasets="dataSetsEstadisticas" :labels="labelEstadisticas" /> <!-- <CChartLineExample/> -->
                    <!-- <CChartLine :datasets="computedDatasets" :options="computedOptions" :labels="labels" /> -->
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i><span class="h5"> Gestión de Usuarios</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="text-center justify-content-center">
                        <b-col cols="4" v-if="checkPermissions('012-USU-LIS','c')==1" class="my-2">
                            <b-button block variant="custom" @click="modalRegistrarUsuario = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br>Nuevo usuario
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i><span class="h5"> Usuarios registrados </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="7">
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table bordered hover show-empty mediun responsive outlined :items="listaUsuarios" :fields="camposUsuarios" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(opciones)="param">
                                    <b-button v-if="checkPermissions('012-USU-LIS','u')==1" size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActualizarUsuario(param)" variant="dark" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                    <b-button v-if="checkPermissions('012-USU-LIS','d')==1 && param.item.idCliente != 0" size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarUsuario(param)" variant="danger">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                </template>

                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>

        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-sign-in-alt fa-md"></i><span class="h5"> Accesos al sistema </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPaginaAccesos" :options="paginaOpcionesAccesos"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="7">
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filterAccesos" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    <!--     <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>-->
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table hover bordered show-empty mediun responsive outlined :items="listaAccesos" :fields="camposAccesos" :current-page="paginaActualAccesos" :per-page="porPaginaAccesos" :filter="filterAccesos" :filter-included-fields="filterOnAccesos" @filtered="onFilteredAccesos" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>

                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" v-model="paginaActualAccesos" :total-rows="filasTotalesAccesos" :per-page="porPaginaAccesos" first-number last-number align="right" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>
    <CModal :show.sync="modalRegistrarUsuario" :closeOnBackdrop="false" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo usuario </span>
            </h6>
            <CButtonClose @click="modalRegistrarUsuario = false" class="text-white" />
        </template>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarUsuario)">
                <b-row>
                    <b-col lg="6">
                        <validation-provider name="nombres" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombres:" class="mb-2">
                                <b-form-input v-on:change="generarNombreUsuario()" :state="getValidationState(validationContext)" placeholder="Ingrese los nombres" v-model="datosUsuario.nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="apellido paterno" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Apellido paterno:" class="mb-2">
                                <b-form-input v-on:change="generarNombreUsuario()" :state="getValidationState(validationContext)" placeholder="Ingrese el apellido paterno" v-model="datosUsuario.apellidoPaterno"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="apellido materno" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Apellido materno:" class="mb-2">
                                <b-form-input v-on:change="generarNombreUsuario()" :state="getValidationState(validationContext)" placeholder="Ingrese el apellido materno" v-model="datosUsuario.apellidoMaterno"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="tipo documento" rules="required" v-slot="{errors}">
                            <b-form-group label="Tipo de documento:" class="mb-2">
                                <v-select :reduce="comboTipoDocumentos => comboTipoDocumentos.idTipoDocumento" label="descripcion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosUsuario.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosUsuario.idTipoDocumento" :options="comboTipoDocumentos" @search:blur="blurTipoDocumento">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="documento de identidad" :rules="{required: true,numeric: true}" v-slot="validationContext">
                            <b-form-group label="Documento de identidad:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el documento de identidad" v-model="datosUsuario.numeroDocumento"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="teléfono" :rules="{numeric: true}" v-slot="validationContext">
                            <b-form-group label="Teléfono:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el teléfono" v-model="datosUsuario.telefono"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="correo" :rules="{required: true, email: true}" v-slot="validationContext">
                            <b-form-group label="Correo:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el correo" v-model="datosUsuario.correo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="usuario" :rules="{required: true,max:15, min:5}" v-slot="validationContext">
                            <b-form-group label="Usuario:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el usuario" v-model="datosUsuario.usuario"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="contraseña" :rules="{required: true,min:8,max:16}" v-slot="validationContext">
                            <b-form-group label="Contraseña:" class="mb-2">
                                <b-form-input type="password" autocomplete="on" :state="getValidationState(validationContext)" placeholder="Ingrese la contraseña" v-model="datosUsuario.contrasena"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="perfil" rules="required" v-slot="{errors}">
                            <b-form-group label="Perfil:" class="mb-2">
                                <v-select :reduce="comboTipoPerfil => comboTipoPerfil.idTipoPerfil" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosUsuario.idTipoPerfil , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosUsuario.idTipoPerfil" :options="comboTipoPerfil" @search:blur="blurPerfil">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Perfiles'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="3">
                        <validation-provider name="horas semanales" v-slot="validationContext">
                            <b-form-group label="Horas semanales:" class="mb-2">
                                <b-form-input type="number" step=".01" v-on:change="calcularHoras()" :state="getValidationState(validationContext)" placeholder="Ingrese las horas semanales" v-model="datosUsuario.horasSemanales"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="3">
                        <validation-provider name="coeficiente" v-slot="validationContext">
                            <b-form-group label="Coeficiente:" class="mb-2">
                                <b-form-input class="disabled-white" disabled type="number" step=".01" :state="getValidationState(validationContext)" v-model="datosUsuario.coeficiente"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="3">
                        <validation-provider name="horas anuales" v-slot="validationContext">
                            <b-form-group label="Horas anuales:" class="mb-2">
                                <b-form-input class="disabled-white" disabled type="number" step=".01" :state="getValidationState(validationContext)" v-model="datosUsuario.horasAnuales"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="3">
                        <validation-provider name="horas mensuales" v-slot="validationContext">
                            <b-form-group label="Horas mensuales:" class="mb-2">
                                <b-form-input class="disabled-white" disabled type="number" step=".01" :state="getValidationState(validationContext)" v-model="datosUsuario.horasMensuales"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="3">
                        <validation-provider name="horas/dia" v-slot="validationContext">
                            <b-form-group label="Horas/dia:" class="mb-2">
                                <b-form-input type="text" v-on:change="calcularHoras()" :state="getValidationState(validationContext)" placeholder="Ingrese las horas/dia" v-model="datosUsuario.horasDia"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistrarUsuario = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalActualizarUsuario" :closeOnBackdrop="false" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar usuario</span></h6>
            <CButtonClose @click="modalActualizarUsuario = false" class="text-white" />
        </template>
        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(modificarUsuario)">
                <b-row>
                    <b-col lg="6">
                        <validation-provider name="nombres" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombres:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese los nombres" v-model="datosActualizarUsuario.nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="apellido paterno" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Apellido paterno:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el apellido paterno" v-model="datosActualizarUsuario.apellidoPaterno"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="apellido materno" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Apellido materno:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el apellido materno" v-model="datosActualizarUsuario.apellidoMaterno"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="tipo de documento" rules="required" v-slot="{errors}">
                            <b-form-group label="Tipo de documento:" class="mb-2">
                                <v-select :reduce="comboTipoDocumentos => comboTipoDocumentos.idTipoDocumento" label="descripcion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarUsuario.idTipoDocumento , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarUsuario.idTipoDocumento" :options="comboTipoDocumentos" @search:blur="blurActualizarTipoDocumento">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="documento de identidad" :rules="{required: true, numeric: true}" v-slot="validationContext">
                            <b-form-group label="Documento de identidad:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el documento de identidad" v-model="datosActualizarUsuario.numeroDocumento"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="teléfono" :rules="{numeric: true}" v-slot="validationContext">
                            <b-form-group label="Teléfono:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el teléfono" v-model="datosActualizarUsuario.telefono"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="correo" :rules="{required: true, email: true}" v-slot="validationContext">
                            <b-form-group label="Correo:" class="mb-2">
                                <b-form-input type="email" :state="getValidationState(validationContext)" placeholder="Ingrese el correo" v-model="datosActualizarUsuario.correo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="usuario" :rules="{required: true, max:15, min:5}" v-slot="validationContext">
                            <b-form-group label="Usuario:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el usuario" v-model="datosActualizarUsuario.usuario"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <validation-provider name="contraseña" :rules="{ max:16, min:8}" v-slot="validationContext">
                            <b-form-group label="Contraseña:" class="mb-2">
                                <b-form-input type="password" autocomplete="on" :state="getValidationState(validationContext)" placeholder="Ingrese la contraseña (opcional)" v-model="datosActualizarUsuario.contrasena"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6" v-if="datosActualizarUsuario.ambitoPerfil != 0">
                        <validation-provider name="perfil" rules="required" v-slot="{errors}">
                            <b-form-group label="Perfil:" class="mb-2">
                                <v-select :reduce="comboTipoPerfil => comboTipoPerfil.idTipoPerfil" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarUsuario.idTipoPerfil , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarUsuario.idTipoPerfil" :options="comboTipoPerfil" @search:blur="blurActualizarPerfil">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="3">
                        <validation-provider name="horas semanales" v-slot="validationContext">
                            <b-form-group label="Horas semanales:" class="mb-2">
                                <b-form-input type="number" step=".01" v-on:change="calcularHorasModificar()" :state="getValidationState(validationContext)" placeholder="Ingrese las horas semanales" v-model="datosActualizarUsuario.horasSemanales"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="3">
                        <validation-provider name="coeficiente" v-slot="validationContext">
                            <b-form-group label="Coeficiente:" class="mb-2">
                                <b-form-input class="disabled-white" disabled type="number" step=".01" :state="getValidationState(validationContext)" v-model="datosActualizarUsuario.coeficiente"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="3">
                        <validation-provider name="horas anuales" v-slot="validationContext">
                            <b-form-group label="Horas anuales:" class="mb-2">
                                <b-form-input class="disabled-white" disabled type="number" step=".01" :state="getValidationState(validationContext)" v-model="datosActualizarUsuario.horasAnuales"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="3">
                        <validation-provider name="horas mensuales" v-slot="validationContext">
                            <b-form-group label="Horas mensuales:" class="mb-2">
                                <b-form-input class="disabled-white" disabled type="number" step=".01" :state="getValidationState(validationContext)" v-model="datosActualizarUsuario.horasMensuales"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="3">
                        <validation-provider name="horas/dia" v-slot="validationContext">
                            <b-form-group label="Horas/dia:" class="mb-2">
                                <b-form-input type="text" v-on:change="calcularHorasModificar()" :state="getValidationState(validationContext)" placeholder="Ingrese las horas/dia" v-model="datosActualizarUsuario.horasDia"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalActualizarUsuario = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>
</div>
</template>

<script>
import {
    CChartLine
} from '@coreui/vue-chartjs'
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {
        CChartLine,
    },
    data() {
        return {
            showEstadistica: false,
            labelEstadisticas: [],
            dataEstadisticas: [],
            optionsEstadisticas: {
                legend: {
                    display: false,
                    labels: {
                        display: false
                    }
                }
            },
            filasTotales: 0,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            filter: null,
            filterOn: [],

            filasTotalesAccesos: 0,
            paginaActualAccesos: 1,
            porPaginaAccesos: 10,
            paginaOpcionesAccesos: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            filterAccesos: null,
            filterOnAccesos: [],

            listaUsuarios: [],
            camposUsuarios: [{
                key: "index",
                label: "N°",
                class: "text-center",
            }, {
                key: "persona",
                label: "Nombre",
                class: "text-center",
            }, {
                key: "telefono",
                label: "Teléfono",
                class: "text-center",
            }, {
                key: "correo",
                label: "Correo",
                class: "text-center",
            }, {
                key: "usuario",
                label: "Usuario",
                class: "text-center",
            }, {
                key: "tipoPerfil",
                label: "Tipo de Perfil",
                class: "text-center",
            }, {
                key: "opciones",
                label: "",
                class: "text-center",
            }],

            datosUsuario: {
                idCliente: '',
                nombre: '',
                apellidoPaterno: '',
                apellidoMaterno: '',
                idTipoDocumento: null,
                numeroDocumento: '',
                telefono: '',
                correo: '',
                usuario: '',
                contrasena: '',
                idTipoPerfil: null,
                horasSemanales: '00.00',
                coeficiente: '00.00',
                horasAnuales: '00.00',
                horasMensuales: '00.00',
                horasDia: '00.00',
                path: JSON.parse(localStorage.settings).path
            },
            datosActualizarUsuario: {
                idCliente: '',
                nombre: '',
                apellidoPaterno: '',
                apellidoMaterno: '',
                idTipoDocumento: null,
                numeroDocumento: '',
                telefono: '',
                correo: '',
                usuario: '',
                contrasena: '',
                idTipoPerfil: null,
                horasSemanales: '',
                coeficiente: '',
                horasAnuales: '',
                horasMensuales: '',
                horasDia: '',
                idPersona: '',
                idUsuario: '',
                ambitoPerfil: '',
                path: JSON.parse(localStorage.settings).path
            },

            camposAccesos: [{
                key: "index",
                label: "N°",
                class: "text-center",
            }, {
                key: "usuario",
                label: "Usuario",
                class: "text-center",
            }, {
                key: "fecha",
                label: "Fecha",
                class: "text-center",
            }, {
                key: "hora",
                label: "Hora",
                class: "text-center",
            }, {
                key: "direccionIp",
                label: "Dirección IP",
                class: "text-center",
            }, {
                key: "navegador",
                label: "Navegador",
                class: "text-center",
            }, {
                key: "sistemaOperativo",
                label: "Sistema Operativo",
                class: "text-center",
            }],

            listaAccesos: [],

            comboTipoPerfil: [],
            comboTipoDocumentos: [],
            modalRegistrarUsuario: false,
            modalActualizarUsuario: false,
            disabled: false,

            datosCalcularHoras: {
                horasSemanalesEmpresa: 40,
                horasAnualesEmpresa: 2680,
                horasDiaEmpresa: 8
            },

        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        onFilteredAccesos(itemsFiltrados) {
            this.filasTotalesAccesos = itemsFiltrados.length
            this.paginaActualAccesos = 1
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        generarNombreUsuario() {
            let me = this;
            let usuario = me.datosUsuario.nombre.substr(0, 1) + me.datosUsuario.apellidoPaterno + me.datosUsuario.apellidoMaterno.substr(0, 1);
            me.datosUsuario.usuario = usuario.toUpperCase();
        },
        generarNombreUsuarioActualizar() {
            let me = this;
            let usuario = me.datosActualizarUsuario.nombre.substr(0, 1) + me.datosActualizarUsuario.apellidoPaterno + me.datosActualizarUsuario.apellidoMaterno.substr(0, 1);
            me.datosActualizarUsuario.usuario = usuario.toUpperCase();
        },
        blurPerfil() {
            this.computedForm.refs.perfil.validate();
        },
        blurActualizarPerfil() {
            this.computedActualizarForm.refs.perfil.validate();
        },
        blurActualizarTipoDocumento() {
            this.computedActualizarForm.refs["tipo de documento"].validate();
        },
        blurTipoDocumento() {
            this.computedForm.refs['tipo documento'].validate();
        },

        calcularHoras() {
            let me = this;
            let horasSemanalesTrabajador = parseInt(me.datosUsuario.horasSemanales);
            let horasSemanalesEmpresa = parseInt(me.datosCalcularHoras.horasSemanalesEmpresa);
            let horasAnualesEmpresa = parseInt(me.datosCalcularHoras.horasAnualesEmpresa);
            let horasDiaEmpresa = parseInt(me.datosCalcularHoras.horasDiaEmpresa);

            let coeficiente = horasSemanalesTrabajador / horasSemanalesEmpresa;
            let horasAnio = horasAnualesEmpresa * coeficiente;
            let horasMes = horasAnio / 12;
            let horasDia = horasDiaEmpresa * coeficiente;

            me.datosUsuario.coeficiente = parseFloat(coeficiente).toFixed(2);
            me.datosUsuario.horasAnuales = parseFloat(horasAnio).toFixed(2);
            me.datosUsuario.horasMensuales = parseFloat(horasMes).toFixed(2);
            me.datosUsuario.horasDia = parseFloat(horasDia).toFixed(2);
            me.datosUsuario.horasSemanales = parseFloat(horasSemanalesTrabajador).toFixed(2);
        },
        calcularHorasModificar() {
            let me = this;
            let horasSemanalesTrabajador = parseInt(me.datosActualizarUsuario.horasSemanales);
            let horasSemanalesEmpresa = parseInt(me.datosCalcularHoras.horasSemanalesEmpresa);
            let horasAnualesEmpresa = parseInt(me.datosCalcularHoras.horasAnualesEmpresa);
            let horasDiaEmpresa = parseInt(me.datosCalcularHoras.horasDiaEmpresa);

            let coeficiente = horasSemanalesTrabajador / horasSemanalesEmpresa;
            let horasAnio = horasAnualesEmpresa * coeficiente;
            let horasMes = horasAnio / 12;
            let horasDia = horasDiaEmpresa * coeficiente;

            me.datosActualizarUsuario.coeficiente = parseFloat(coeficiente).toFixed(2);
            me.datosActualizarUsuario.horasAnuales = parseFloat(horasAnio).toFixed(2);
            me.datosActualizarUsuario.horasMensuales = parseFloat(horasMes).toFixed(2);
            me.datosActualizarUsuario.horasDia = parseFloat(horasDia).toFixed(2);
            me.datosActualizarUsuario.horasSemanales = parseFloat(horasSemanalesTrabajador).toFixed(2);
        },
        listarUsuarios() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-usuarios", {
                    params: {
                        idCliente: me.datosUsuario.idCliente
                    }
                })
                .then(function (response) {
                    me.listaUsuarios = response.data;
                    me.filasTotales = me.listaUsuarios.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarAccesosSistema() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "generales/obtener-accesos-sistema", {
                    params: {
                        idCliente: me.datosUsuario.idCliente
                    }
                })
                .then(function (response) {
                    me.listaAccesos = response.data;
                    me.filasTotalesAccesos = me.listaAccesos.length;
                    me.obtenerHoraEstadistica();

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        registrarUsuario() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-usuario",
                    me.datosUsuario, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    me.disabled = false;
                    let color = response.data.resultado == 1 ? "success" : "error";
                    if (response.data.alert) {
                        return me.swat(color, response.data.mensaje)
                    }
                    me.swat(color, response.data.mensaje);
                    me.listarUsuarios();
                    me.modalRegistrarUsuario = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        modificarUsuario() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/modificar-usuario",
                    me.datosActualizarUsuario, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.disabled = false;
                    if (response.data.alert) {
                        return me.swat(color, response.data.mensaje)
                    }
                    me.swat(color, response.data.mensaje);
                    me.listarUsuarios();
                    me.modalActualizarUsuario = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        obtenerTipoDocumento() {
            let me = this;
            (axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`);
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "generales/obtener-tipo-documento")
                .then(function (response) {
                    me.comboTipoDocumentos = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        obtenerTipoPerfiles() {
            let me = this;
            (axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`);
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-perfiles", {
                    params: {
                        idCliente: me.datosUsuario.idCliente
                    }
                })
                .then(function (response) {
                    me.comboTipoPerfil = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        abrirModalActualizarUsuario(param) {

            this.datosActualizarUsuario.nombre = param.item.persona;
            this.datosActualizarUsuario.apellidoPaterno = param.item.apellidoPaterno;
            this.datosActualizarUsuario.apellidoMaterno = param.item.apellidoMaterno;
            this.datosActualizarUsuario.idTipoDocumento = param.item.idTipoDocumento;
            this.datosActualizarUsuario.numeroDocumento = param.item.numeroDocumento;
            this.datosActualizarUsuario.telefono = param.item.telefono;
            this.datosActualizarUsuario.correo = param.item.correo;
            this.datosActualizarUsuario.usuario = param.item.usuario;
            this.datosActualizarUsuario.idTipoPerfil = param.item.idTipoPerfil;
            this.datosActualizarUsuario.horasSemanales = param.item.horasSemanales;
            this.datosActualizarUsuario.coeficiente = param.item.coeficiente;
            this.datosActualizarUsuario.horasAnuales = param.item.horasAnuales;
            this.datosActualizarUsuario.horasMensuales = param.item.horasMensuales;
            this.datosActualizarUsuario.horasDia = param.item.horasDia;
            this.datosActualizarUsuario.idPersona = param.item.idPersona;
            this.datosActualizarUsuario.idUsuario = param.item.idUsuario;
            this.datosActualizarUsuario.ambitoPerfil = param.item.idCliente;
            this.modalActualizarUsuario = true;

        },
        resetModalRegistrarUsuario() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosUsuario.nombre = '';
            this.datosUsuario.apellidoPaterno = '';
            this.datosUsuario.apellidoMaterno = '';
            this.datosUsuario.numeroDocumento = '';
            this.datosUsuario.correo = '';
            this.datosUsuario.telefono = '';
            this.datosUsuario.usuario = '';
            this.datosUsuario.contrasena = '';
            this.datosUsuario.idTipoDocumento = '';
            this.datosUsuario.idTipoPerfil = '';
            this.datosUsuario.horasSemanales = '00.00';
            this.datosUsuario.coeficiente = '00.00';
            this.datosUsuario.horasAnuales = '00.00';
            this.datosUsuario.horasMensuales = '00.00';
            this.datosUsuario.horasDia = '00.00';

        },
        resetModalActualizarUsuario() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
            this.datosActualizarUsuario.contrasena = '';
        },
        eliminarUsuario(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el usuario?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-usuario", {
                            idUsuario: param.item.idUsuario,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarUsuarios();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        obtenerHoraEstadistica() {
            //    //Filtrado multiple
            //     let filtro = {
            //         fecha: ``
            //     };
            //     //Uso de los filtros
            //     let arr = this.listaAccesos.filter(function (item) {
            //         for (var key in filtro) {
            //             if (item[key] === undefined || item[key] != filtro[key])
            //                 return false;
            //         }
            //         return true;
            //     });

            //Filtrando por hoy 
            let hoy = moment().format('DD/MM/YYYY')
            let arr = this.listaAccesos.filter(x => x.fecha == hoy);

            //Removiendo horas duplicadas y ordenando
            let fields = removerUnicas(arr, 'hora').sort(function (a, b) {
                return parseInt(parseInt(a.hora.split(':')[0]) - b.hora.split(':')[0])
            })

            function removerUnicas(arr, key) {
                return [...new Map(arr.map(item => [item[key].split(':')[0], item])).values()]
            }

            //Set label estadistica
            for (let k of fields) {
                this.labelEstadisticas.push(`${k.hora.split(':')[0]}:00`)
            }

            /*------------------------*/

            //set data 
            let datos = [];
            for (let j of arr) {
                datos.push(j.hora.split(":")[0])
            }
            datos = datos.sort(function (a, b) {
                return a - b;
            });

            //contar datos y settear al chart
            let item = null;
            let cont = 0;
            for (let i = 0; i < datos.length; i++) {
                if (datos[i] != item) {
                    if (cont > 0) {
                        this.dataEstadisticas.push(cont)
                    }
                    item = datos[i];
                    cont = 1;
                } else {
                    cont++;
                }
            }
            if (cont > 0) {
                this.dataEstadisticas.push(cont)
            }

            this.showEstadistica = true;
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedActualizarForm() {
            return this.$refs.observer2;
        },
        dataSetsEstadisticas() {
            return [{
                    backgroundColor: '#dedede',
                    data: this.dataEstadisticas
                },

            ]
        }
    },
    watch: {
        modalRegistrarUsuario: function (val) {
            if (val == false) {
                this.resetModalRegistrarUsuario();
            }
        },
        modalActualizarUsuario: function (val) {
            if (val == false) {
                this.resetModalActualizarUsuario();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosUsuario.idCliente = this.datosActualizarUsuario.idCliente = user.uidClient;
            this.obtenerTipoDocumento();
            this.obtenerTipoPerfiles();
            this.listarUsuarios();
            this.listarAccesosSistema();
        }
    }
}
</script>
